<template lang="pug">
	.landing
		div
			div(v-show="tab == 0")
				Header(@click="goToLogin" @monetization="tab = 1" @all="tab = 0" :idPage="tab")
				.landing__main
					.pt-0.landing__container(ref="container")
						Directions(@monetization="tab = 1")
				Footer(v-if="!islocaleRuSystemAndIp" @monetization="tab = 1")
				FooterRuDomain(v-if="islocaleRuSystemAndIp" @monetization="tab = 1")

			.landing__monetization(v-show="tab == 1")
				Header(@click="goToLogin" @monetization="tab = 1" @all="tab = 0" :idPage="tab")
				.landing__plain
					.pt-0.landing__container
						Preview(
							:type="'plain'"
							:title="$t('landingPage.directions.monetization.title')",
							:description="$t('landingPage.directions.monetization.txt')",
							:button="$t('landingPage.preview.monetization.button')"
							:tab="tab"
							@click="goToLogin"
						)
				Authors(:authorsKey="landingKey + 1" :marginLeftContainer="marginLeftContainer")
				Reviews(:reviewsKey="landingKey + 1")
				Write(v-if="islocaleRuSystemAndIp")
				.overflow-hidden.landing__container
					Steps
				.landing__container(v-if="islocaleRuSystemAndIp")
					Features(@click="goToLogin" :tab="tab")
				Commission(@click="goToLogin")
				Support

				.landing__container(v-if="islocaleRuSystemAndIp")
					Articles
				Footer(v-if="!islocaleRuSystemAndIp" @monetization="tab = 1")
				FooterRuDomain(v-if="islocaleRuSystemAndIp" @monetization="tab = 1")
				

			ModalCookie
</template>

<script>
import { mapActions } from 'vuex';
import { DEVICE } from '@/utils/device';

/*Общий*/
import Header from '@/components/landingPage/Header';
import Directions from '@/components/landingPage/Directions';
import Preview from '@/components/landingPage/Preview';
import Footer from '@/components/landingPage/Footer';
import FooterRuDomain from '@/components/landingPage/FooterRuDomain';
import ModalCookie from '@/components/Modals/Cookie';

/*Монетизация*/
import Authors from '@/components/landingPage/Plain/Authors';
import Reviews from '@/components/landingPage/Plain/Reviews';
import Write from '@/components/landingPage/Plain/Write';
import Steps from '@/components/landingPage/Plain/Steps';
import Features from '@/components/landingPage/Plain/Features';
import Commission from '@/components/landingPage/Plain/Commission';
import Support from '@/components/landingPage/Plain/Support';
import Articles from '@/components/landingPage/Plain/Articles';

export default {
	name: 'index',
	metaInfo() {
		return {
			titleTemplate: this.islocaleRuSystemAndIp ? 'Paywall - монетизация контента, сообществ и нейросетей в Телеграме.' : 'Paywall - Content monetization service',
		};
	},
	components: {
		Header,
		Directions,
		Preview,
		Footer,
		FooterRuDomain,
		ModalCookie,

		Authors,
		Reviews,
		Write,
		Steps,
		Features,
		Commission,
		Support,
		Articles,
	},
	data: () => ({
		tab: 0,
		marginLeftContainer: '',
		landingKey: 0,
		lastScrollPosition: 0
	}),
	created() {
		this.viewGeoIpRussia();
	},
	mounted()	{
		window.addEventListener('beforeunload', this.handleBeforeUnload);

		if (this.$route.hash) {
			setTimeout(() => this.scrollTo(this.$route.hash), 100)
		}

		this.rememberPageView();

		this.$nextTick(() => {
			const fixedMarginLeft = DEVICE.isMobile() ? '12.5px' : '16px';

			const containerElement = this.$refs.container;
			const computedStyles = window.getComputedStyle(containerElement);
			const computedMarginLeft = computedStyles.getPropertyValue('margin-left');
			this.marginLeftContainer = parseFloat(computedMarginLeft) > parseFloat(fixedMarginLeft) ? computedMarginLeft : fixedMarginLeft;

			if(parseInt(this.marginLeftContainer) >= 184) {
				this.marginLeftContainer = '184px';
			}
		});
	},
	beforeMount () {
		this.redirectToLoginIfMerchantPath();
	},
	beforeDestroy() {
		window.removeEventListener('beforeunload', this.handleBeforeUnload);
  },
	beforeRouteEnter(to, from, next) {
		next(vm => {
			if(from.name != 'index') {
				if(to.params.type == 'monetization') {
					vm.tab = 1;
				} 
				
				if(to.params.block) {
					let distanceBlock = 0;
					
					if(!to.params.distance) {
						distanceBlock = -30;
					} else {
						distanceBlock = to.params.distance;
					}

					vm.$nextTick(() => {
						const targetBlock = document.querySelector(to.params.block);

						setTimeout(() => {
							const top = window.scrollY + targetBlock.getBoundingClientRect().y + distanceBlock;

							window.scrollTo(0, top);
						}, 1000);
					});
				}
			}
		});
	},
	methods: {
		...mapActions({
			getGeoIpRussia: 'auth/geoIpRussia'
		}),
		redirectToLoginIfMerchantPath () {
			if ( window.location.href.includes('merchant') ) {
				if( this.me && this.me.id ) return this.$router.replace({name: 'projects'})

				return this.goToLogin();
			}
		},
		goToLogin(e) {
			if (e) {
				e.preventDefault();
				e.stopPropagation();
			}
			window.location.href = this.loginUrl;
		},
		handleBeforeUnload() {
			window.scrollTo({ top: 0, behavior: "instant"});
		}
	},
	watch: {
		tab() {
			this.landingKey++;
			window.scrollTo({
				top: 0
			});
		}
	}
}
</script>

<style lang="scss">
@import '~@/assets/css/sass/_landing.style.scss';
</style>
