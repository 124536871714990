<template lang="pug">
	section.directions
		.directions__item
			.directions__inner
				h2.directions__subtitle {{ $t('landingPage.directions.monetization.title') }}
				p.directions__txt {{ $t('landingPage.directions.monetization.txt') }}
				.landing__btn.directions__btn(@click="$emit('monetization')") {{ $t('landingPage.directions.moreDetails') }}
			
			.directions__video(v-if="islocaleRuSystemAndIp" @click="openVideo")
				img(:src="isMobile ? '/assets/img/landingPage/direction/monetization-mobile.webp' : '/assets/img/landingPage/direction/monetization.webp'")

				.directions__play
					.directions__play-icon
			
			.directions__image(v-if="!islocaleRuSystemAndIp")
				img(:src="isMobile ? '/assets/img/landingPage/direction/monetization-mobile.webp' : '/assets/img/landingPage/direction/monetization.webp'")

		.directions__item
			.directions__inner
				h2.directions__subtitle {{ $t('landingPage.directions.neurobot.title') }}
				p.directions__txt {{ $t('landingPage.directions.neurobot.txt') }}
				.landing__btn.is-green.directions__btn(@click="goToNeurobot") {{ $t('landingPage.directions.moreDetails') }}
			
			.directions__image
				img(:src="isMobile ? '/assets/img/landingPage/direction/neurobot-mobile.webp' : '/assets/img/landingPage/direction/neurobot.webp'")
		
		.video-modal(v-if='isShowVideo' @click="closeVideo")
			video.d-block(autoplay :poster="'/assets/img/landingPage/Plain/poster.webp'" loop controls playsinline)
				source(v-if="!isMobile" src="/assets/video/landingPage/Plain/preview/video.webm" type="video/webm")
				source(:src="isMobile ? '/assets/video/landingPage/Plain/preview/isMobile/video.mp4' : '/assets/video/landingPage/Plain/preview/video.mp4'" type="video/mp4")
			
			.close-modal
				.close-modal__icon

</template>

<script>
export default {
	name: 'Directions',
	data: () => ({
		isShowVideo: false
	}),
	methods: {
		openVideo() {
			this.isShowVideo = true;
			document.body.classList.add('modal-open');
		},
		closeVideo(event) {
			if(event.target.tagName != 'VIDEO') {
				this.isShowVideo = false;
				document.body.classList.remove('modal-open');
			}
		},
		goToNeurobot() {
			this.$router.push({name: 'landingNeurobot'});
		}
	}
}

</script>

<style lang="scss" scoped>

.directions {
	margin-bottom: 17px;
	margin-top: -0.5px;

	&__item {
		margin-bottom: 32px;
		background-color: var(--lc-transparent-white);
		border-radius: 40px;
		padding: 42px 0 40px 44px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		overflow: hidden;

		@media (max-width: 991.98px) {
			padding: 35px 0 33px 34px;
		}

		@media (max-width: 767.98px) {
			flex-wrap: wrap;
			padding: 29px 25px 27px 25px;
			border-radius: 20px;
			margin-bottom: 26px;
		}

		@media(max-width: 575.98px) {
			padding: 22px 15px 20px 15px;
			margin-bottom: 19.5px;
		}
	}

	&__inner {
		width: 51%;
		margin-top: -1px;

		@media (max-width: 767.98px) {
			width: 100%;
		}
	}

	&__subtitle {
		font-size: 28px !important;
		line-height: 37px !important;

		@media (max-width: 991.98px) {
			font-size: 27px !important;
			line-height: 34px !important;
		}

		@media (max-width: 767.98px) {
			text-align: center;
			font-size: 25px !important;
			line-height: 31px !important;
		}

		@media(max-width: 575.98px) {
			font-size: 24px !important;
			line-height: 28px !important;
		}
	}

	&__txt {
		color: var(--lc-grey);
		line-height: calc(25 / 16);
		margin-top: 24px;

		@media (max-width: 991.98px) {
			font-size: 15px;
		}

		@media (max-width: 767.98px) {
			text-align: center;
			margin-top: 30px;
		}

		@media(max-width: 575.98px) {
			font-size: 14px;
			line-height: 19px;
		}
	}

	&__btn {
		margin-top: 23px;

		@media (max-width: 767.98px) {
			margin: 30px auto;
		}

		@media(max-width: 575.98px) {
			width: 100%;
		}
	}

	&__video {
		position: relative;

		&::before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: rgba(0, 0, 0, 0.3);
			opacity: 0;
			border-radius: inherit;
			transition: opacity var(--la-time);
		}
	}

	&__video, &__image {
		width: 40.17%;
		height: auto;
		border-radius: 40px 0 0 40px;
		transition: transform var(--la-time);
		margin-right: -0.5px;

		@media (max-width: 767.98px) {
			width: 100%;
			max-width: 464px;
			border-radius: 20px;
			margin: 0 auto;
		}

		img {
			height: 100%;
			width: 100%;
			border-radius: inherit;
		}

		&:has(> .directions__play:hover) {
			transform: scale(1.02);
			
			&:before {
				opacity: 1;
			}
		}
	}

	&__play {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 41.9%;
		margin: auto 0;
		cursor: pointer;
		background-color: var(--lc-transparent-2-white);
		border-radius: 50%;
		width: 140px;
		height: 140px;
		display: flex;
		align-items: center;
		justify-content: center;

		@media (max-width: 991.98px) {
			width: 111px;
			height: 111px;
		}

		@media (max-width: 767.98px) {
			width: 83px;
			height: 83px;
			left: 0;
			right: 0;
			margin: auto;
		}

		@media(max-width: 575.98px) {
			width: 54px;
			height: 54px;
		}

		&-icon {
			width: 59.7%;
			height: 59.7%;
			background: url("data:image/svg+xml,%3Csvg width='105' height='105' viewBox='0 0 105 105' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M18.3066 37.4331C18.3066 18.5984 31.6444 10.8855 47.965 20.3029L61.1323 27.8879L74.2996 35.473C90.6202 44.8903 90.6202 60.3161 74.2996 69.7335L61.1323 77.3185L47.965 84.9036C31.6444 94.321 18.3066 86.6081 18.3066 67.7733V52.6032V37.4331Z' fill='white' stroke='white' stroke-width='1.3' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath opacity='0.01' fill-rule='evenodd' clip-rule='evenodd' d='M103.532 1.46875V103.739H1.26172V1.46875H103.532Z' stroke='%23007AA3' stroke-width='1.3'/%3E%3C/svg%3E") no-repeat center / cover;
		}
	}
}

</style>