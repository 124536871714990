<template lang="pug">
	section.articles
		h2.articles__title(id="articlesTitle" v-html="$t('landingPage.articles.title')")

		.articles__list(v-if="articles")
			a.articles__item(v-for="(item, i) in articles" :key="`article-${i}`" :href="item.link" target="_blank")
				img.articles__item-img(:src="item.yoast_head_json.og_image[0].url" :alt="item.title.rendered")
				.articles__item-inner
					span.articles__item-title(v-html="item.title.rendered")
					span.text-grey.articles__item-date {{ item.date_gmt | dateDDMMMYYYYDots }}

</template>

<script>
import axios from 'axios';
import { APIURL } from '@/api/index.js';

export default {
	name: 'Articles',
	data: () => ({
		articles: [],
		key: 0
	}),
	mounted() {
		this.getArticles();
	},
	methods: {
		async getArticles() {
			try {
				const responce = await axios.get(`${APIURL}public/blog/posts`);
				this.articles = responce.data;
				this.key++
			} catch (error) {
			}
		}
	}
}
</script>

<style lang="scss">

.articles {
	padding: 45px 0 0;
	overflow: hidden;
	margin-bottom: -15px;

	@media (max-width: 767.98px) {
		padding-top: 70px;
		margin-bottom: 2px;
	}

	&__title {
		max-width: 600px;
		margin: 0 auto 59px !important;
		text-align: center;

		@media (max-width: 767.98px) {
			margin-bottom: 46px !important;
		}

		@media (max-width: 575.98px) {
			margin-bottom: 33px !important;
		}
	}

	&__list {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		margin-right: -32px;

		@media (max-width: 991.98px) {
			margin-right: -20px;
		}
	}

	&__item {
		max-width: 348px;
		margin-right: 32px;
		margin-bottom: 32px;
		box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.05);
		border-radius: 20px;
		width: calc(100% / 3 - 32px);
		transition: transform var(--la-time);

		@media (max-width: 991.98px) {
			width: calc(50% - 20px);
			margin-right: 20px;
			margin-bottom: 20px;
		}

		@media (max-width: 767.98px) {
			&:nth-of-type(5), &:nth-of-type(6) {
				display: none;
			}
		}

		@media (max-width: 575.98px) {
			width: 100%;
			margin-bottom: 34px;

			&:nth-of-type(4) {
				display: none;
			}
		}

		&:hover {
			transform: scale(1.02);
		}

		&-img {
			width: 100%;
			height: 196px;
			object-fit: cover;
			border-radius: 20px 20px 0 0;
		}

		&-inner {
			height: calc(100% - 196px);
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			border: 1px solid var(--lc-light-grayish-blue);
			border-top: 0;
			border-radius: 0 0 20px 20px;
			padding: 21.5px 19px 24.5px;
		}

		&-title {
			display: block;
			font-size: 22px;
			font-weight: 700;
			line-height: 28px;
			color: var(--lc-ebony);
			margin-bottom: 15px;
		}

		&-date {
			font-size: 14px;
		}
	}
}

</style>