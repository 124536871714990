<template lang="pug">
	section.features
		h2.features__title(id="featuresTitle")
			span.d-block {{ $t(`landingPage.features.title`) }}
			span.color-grey Paywall

		b-tabs.features__tabs(v-if="!isMobile" vertical v-model='tabIndex' no-fade)
			b-tab(v-for="(item, i) in titles" :key="`feature-${i}`")
				template(v-slot:title)
					span(:class="{'first-tab' : i == 0}" @click="resetTitles(i, item)") {{ item }}
				.features__wrap.h-100(v-if="tabIndex == i" @wheel.prevent="debouncedHandleWheel")
					p.features__txt(v-html="$t(`landingPage.features.list[${tabIndex}].text`)")
					.landing__btn.is-light-primary.features__btn(@click="$emit('click', $event)") {{$t('landingPage.preview.monetization.button') }}
					.features__picture(:style="`background-image: url('/assets/img/landingPage/Plain/features/features-${tabIndex}.webp');`" :class="`is-${tabIndex}`")

		.accordion(v-if="isMobile" role='tablistBrand')
			div(v-for="(item, index) in 16" :key="`feature-${index}`")
				div(role='tabBrand')
					.nav-link(v-b-toggle="`accordion-features-${index}`")
						a {{ $t(`landingPage.features.list[${index}].title`) }}
				b-collapse(:id="`accordion-features-${index}`" :visible="index === 0" accordion='my-accordion-features' role='tabpanelBrand')
					p.features__txt(v-html="$t(`landingPage.features.list[${index}].text`)")
					img.features__image(:src="`/assets/img/landingPage/Plain/features/features-${index}.webp`" :alt="$t(`landingPage.features.list[${index}].title`)")
					.landing__btn.is-light-primary.features__btn(@click="$emit('click', $event)") {{$t('landingPage.preview.monetization.button') }}

</template>

<script>
export default {
	name: 'Features',
	props: ['tab'],
	data: () => ({
		tabIndex: 0,
		allTitles: [],
		titles: [],
		timer: 0,
		clickOnElement: 1,
		newTabIndex: 0
	}),
	created() {
		this.allTitles = this.$store.getters['auth/geoIpRussia'] || this.isLocaleRu ? [
				"Доступ по подписке",
				"Каналы и группы",
				"Быстро и удобно",
				"Стабильный доход",
				"Красивый лендинг",
				"Данные об аудитории",
				"Акции и промокоды",
				"Платежи со всего мира",
				"Аналитика",
				"Интеграции",
				"Вебхуки",
				"Восстановление подписок",
				"Физ. лица, ИП и юр. лица",
				"Чарджбеки и возвраты",
				"Помощь в развитии",
				"Поддержка подписчиков"
			] : [
				"Subscription access",
				"Channels and groups",
				"Fast and convenient",
				"Stable income",
				"Beautiful landing",
				"Audience Data",
				"Promotions and promotional codes",
				"Payments from all over the world",
				"Analytics",
				"Integrations",
				"Webhooks",
				"Recovery of subscriptions",
				"Individuals, individual entrepreneurs and legal entities",
				"Chargebacks and returns",
				"Assistance in development",
				"Subscriber support"
			];
		this.titles = this.allTitles.slice();
		this.debouncedHandleWheel = _.debounce(this.handleWheel, 100);
	},
	methods: {
		handleWheel(event) {
			event.preventDefault();
			setTimeout(() => {
				const navLinkActive = document.querySelector('.features__tabs .active');
				if(navLinkActive) {
					navLinkActive.classList.remove('active');
				}
			}, 0);

			if (this.timer == 0) {
				this.titles = this.$t(`landingPage.features.titles`).slice();
				this.timer++
			}

			if (this.tabIndex < 15 && this.tabIndex >= 0 && event.deltaY > 0) {
				this.tabIndex++;
				this.newTabIndex++;
				let firstElement = this.titles.shift(); // Удаление первого элемента
				this.titles.push(firstElement); // Помещение удаленного элемента в конец массива
			} else if (this.tabIndex <= 15 && this.tabIndex > 0 && event.deltaY < 0) {
				this.tabIndex--;
				this.newTabIndex--;
				let lastElement = this.titles.pop(); // Удаление последнего элемента
				this.titles.unshift(lastElement); // Помещение удаленного элемента в начало массива
			}
		},
		resetTitles(i, title) {
			if(this.clickOnElement == 1) {
				this.titles = this.$t(`landingPage.features.titles`);

				setTimeout(() => {
					this.tabIndex = this.titles.indexOf(title);

					this.newTabIndex = this.tabIndex;

					const removedElements = this.titles.splice(0, this.tabIndex);
					this.titles.push(...removedElements);
				}, 10);
			}

			if(this.clickOnElement != 1){
				setTimeout(() => {
					if(this.newTabIndex + i > 15) {
						this.tabIndex = this.newTabIndex - 15 + i - 1;
					} else {
						this.tabIndex = this.newTabIndex + i;
					}
					
					this.newTabIndex = this.tabIndex;

					const removedElements = this.titles.splice(0, i);
					this.titles.push(...removedElements);
				}, 10);
			}

			setTimeout(() => {
				const navLinkActiveElems = document.querySelectorAll('.features__tabs .nav-link.active');
				navLinkActiveElems.forEach((navLinkActiveEl) => {
					navLinkActiveEl.classList.remove('active');
				});

				this.clickOnElement++;
			}, 15);
		}
	}
}
</script>

<style lang="scss">

.features {
	max-width: 1120px;
	margin: 14px auto 0;
	width: 100%;

	@media (max-width: 767.98px) {
		margin-top: 32px;
	}

	.col-auto, .tab-content {
		padding-top: 58px !important;

		@media (max-width: 767.98px) {
			padding-top: 45px !important;
		}

		@media (max-width: 575.98px) {
			padding-top: 32.5px !important;
		}
	}

	&__tabs {
		&.is-fixed {
			position: fixed;
			left: 0;
			right: 0;
			top: 0;
			margin: 0 auto;
			max-width: 1152px;
			padding: 0 8px;
		}
	}

	&:has(.features__tabs.is-fixed) {
		padding-bottom: 2000px;
	}

	.tabs {
		.col-auto {
			padding: 0;
			max-width: 437px;
		}

		.tab-content {
			padding: 1px 9px 0 27px;
			width: calc(100% - 424px);
			max-height: calc(100vh - 58px);

			@media (max-width: 767.98px) {
				padding: 0;
			}

			.tab-pane {
				height: 100%;
				display: flex;
				flex-direction: column;
			}
		}

		.nav-tabs {
			white-space: normal;
		}

		.nav-link {
			padding: 0;
			cursor: default;
			margin-bottom: 26px;
			display: inline-block;

			@media (max-width: 991.98px) {
				margin-bottom: 23px;
			}

			span {
				padding: 0 7px;
				line-height: 48px;
				font-size: 32px;
				font-weight: 700;
				letter-spacing: -0.011em;
				display: inline-block;

				@media (max-width: 991.98px) {
					font-size: 28px;
					line-height: 42px;
				}
			}

			&.active {
				span {
					color: var(--primary-text-color);
				}

				&::before {
					display: none;
				}
			}

			span {
				color: var(--lc-light-grayish-silver);
				cursor: pointer;
			}
		}
	}

	.first-tab {
		color: var(--primary-text-color) !important;
	}

	&__wrap {
		cursor: row-resize;
	}

	.accordion {
		@media (max-width: 767.98px) {
			margin-top: 46px;
		}

		@media (max-width: 575.98px) {
			margin-top: 32px;
		}

		.nav-link {
			color: var(--lc-light-grayish-silver);
			font-size: 24px;
			line-height: 36px;
			margin-bottom: 25px;
			font-weight: 700;
			letter-spacing: -0.011em;
			padding: 0;

			a {
				cursor: pointer;
			}

			&.not-collapsed {
				color: var(--primary-text-color)
			}
		}
	}

	&__txt {
		line-height: 24px;
		letter-spacing: -0.011em;
	}

	&__picture {
		height: 100%;
		background-size: cover;
		background-repeat: no-repeat;
		margin-top: 30px;
		margin-bottom: 24px;
		border: 1px solid #DCEAF8;
		border-radius: 20px;
		max-height: 476px;

		&.is-2 {
			border-color: #FFDDFB;
			max-height: 651px;
		}

		&.is-3 {
			border-color: #DFE1F8;
		}

		&.is-6 {
			border-color: #E2E8FF;
			max-height: 807px;
		}

		&.is-8 {
			border-color: #E2E8FF;
			max-height: 906px;
		}

		&.is-9 {
			border-color:#E7F8C5;
		}

		&.is-10 {
			border-color:#FDECD4;
			max-height: 382px;
		}

		&.is-11 {
			border-color: #FBEAEA;
			max-height: 557px;
		}

		&.is-12 {
			max-height: 486px;
		}

		&.is-13 {
			border-color:#D9F3DB;
			max-height: 354px;
		}

		&.is-14 {
			border-color:#EFEFF1;
		}

		&.is-15 {
			max-height: 393px;
		}
	}

	&__image {
		margin: 15px auto 31px;
		width: 100%;
		max-width: 500px;
		max-height: 400px;
		display: block;
	}

	&__btn {
		max-width: 316px;
		margin-top: 28px;

		@media (max-width: 767.98px) {
			margin: 0 auto 25px;
			width: 100% !important;
			max-width: 400px;
		}
	}
}

</style>
